<template>
  <div class="addCustomer">
    <el-form :model="form" ref="formRef" class="demo-form-inline" label-width="100" :rules="rulers">
      <el-row>
        <el-col :span="11">
          <el-form-item label="客户姓名" prop="name">
            <el-input v-model="form.name" autocomplete="off" style="width: 300px;" placeholder="客户姓名" />
          </el-form-item>
        </el-col>
        <el-col :offset="2" :span="11">
          <el-form-item label="手机号" prop="phone" type="number">
            <el-input v-model="form.phone" autocomplete="off" style="width: 300px;" placeholder="手机号" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="性别" autocomplete="off" prop="gender">
            <el-select v-model="form.gender">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="2" :span="11">
          <el-form-item label="年龄" prop="age">
            <el-input v-model.number="form.age" autocomplete="off" type="number" placeholder="年龄" style="width: 80px;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="身高" prop="height">
            <el-input v-model.number="form.height" type="number" placeholder="身高(cm)" style="width: 120px;" />
          </el-form-item>
        </el-col>
        <el-col :offset="2" :span="11">
          <el-form-item label="体重" prop="weight">
            <el-input v-model.number="form.weight" type="number" placeholder="体重(kg)" style="width: 120px;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="居住地" prop="habitation">
            <el-cascader placeholder="请选择地址" v-model="form.habitation" :options="address_china" :props="props" style="width: 300px" />
          </el-form-item>
        </el-col>
        <el-col :offset="2" :span="11">
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="form.address" placeholder="详细地址" style="width: 300px;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="发货地址" prop="shipping">
            <el-cascader placeholder="请选择发货地址" v-model="form.shipping" :options="address_china" :props="props" style="width: 300px" />
          </el-form-item>
        </el-col>
        <el-col :offset="2" :span="11">
          <el-form-item label="详细地址" prop="shipping_detail">
            <el-input v-model="form.shipping_detail" placeholder="发货详细地址" style="width: 300px;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
          <el-form-item label="备注" prop="partner_remark">
            <el-input v-model="form.partner_remark" :rows="4" style="width: 300px;" type="textarea"></el-input>
          </el-form-item>
      </el-row>
      <el-row justify="space-between" align="middle">
        <el-button type="primary" @click="submitForm(formRef)">保存</el-button>
      </el-row>
    </el-form>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import axiosIns from '@/libs/axios'
import addressData from '@/assets/provinces.json'

// 姓名，手机号，性别，年龄，身高，体重，地址、发货地址，前四项必填
const address_china = addressData.data
// 提交客户数据表单
const form = reactive({
  name: '',
  gender: '',
  phone: null,
  age: '',
  height: null,
  weight: null,
  habitation: [],
  address: '',
  shipping_address: '',
  shipping: [],
  shipping_detail: '',
  partner_remark: ''
})

// 性别选择器数据
const genderList = reactive([
  { label: '男', value: '1' },
  { label: '女', value: '2' }
])

// 级联选择器次级菜单打开方式
const props = { expandTrigger: 'click' }
const formRef = ref(null)
const rulers = {
  name: [{ required: true, message: '此项必填', trigger: 'blur' }],
  phone: [{ required: true, pattern: '^1\\d{10}$', message: '手机号格式错误' }],
  gender: [{ required: true, message: '此项必填', trigger: 'blur' }],
  age: [{ required: true, message: '年龄0~150岁', trigger: 'blur' }]
}

const validate = (formRef, callback) => {
  formRef.validate(valid => {
    if (valid) callback()
  })
}

// 提交表单
const submitForm = async (formRef) => {
  validate(formRef, async () => {
    try {
      const postData = JSON.parse(JSON.stringify(form))
      postData.habitation = postData.habitation.join('-')
      postData.shipping_address = postData.shipping.join('-') + '-' + postData.shipping_detail
      const response = await axiosIns.post('/users/add', postData)
      formRef.resetFields()
    } catch (error) {
      console.error(error)
    }
  })
}

onMounted(() => {
  form.gender = '1'
})
</script>
<style lang="less" scoped>
.addCustomer {
  .el-select {
    width: 80px;
  }
  .el-button {
    width: 100px;
    margin-top: 20px;
    margin-left: 100px;
  }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
